<template>
  <ReporteTransaccionesDetalladasPorProveedorOCliente
    titulo="Reportes - Compras Detalladas por Proveedor"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    tipoTransaccion="compra"
    :servicioBuscarClienteOProvedor="servicioBuscarProveedor"
  />
</template>

<script>
import ReporteTransaccionesDetalladasPorProveedorOCliente from "../../components/ReporteTransaccionesDetalladasPorProveedorOCliente.vue";
import ReporteComprasService from "../services";
import { ProveedorService } from "../../../administracion/cliente_proveedor/services/index";

export default {
  name: "ReporteComprasDetalladasPorProveedorLayout",
  components: {
    ReporteTransaccionesDetalladasPorProveedorOCliente,
  },
  methods: {
    servicioGetReporte:
      ReporteComprasService.getReporteComprasDetalladasPorProveedor,
    servicioGetReportePdf:
      ReporteComprasService.getReporteComprasDetalladasPorProveedorPdf,
    servicioBuscarProveedor: ProveedorService.buscar,
  },
};
</script>